import { Box, Button, Container, styled, Typography } from "@mui/material";
import React, { ReactElement, FC } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";

const Banner: FC<any> = (): ReactElement => {
  const MyBotton = styled(Button)({
    background: "transparent",
    border: "1px solid #1E1945",
    color: "#1E1945",
    padding: "0.6rem 1.2rem",
    margin: "0.8rem 0.8rem 0 0",
    "&:hover": {
      background: "#3333330a",
      border: "1px solid #1E1945",
    },
    "@media (max-width:600px)": {
      padding: "0.4rem 0.8rem",
      margin: "0.4rem 0.4rem 0 0",
      fontSize: "10px",
    },
  });

  return (
    <>
      <Box
        component="section"
        sx={{
          mt: 3,
        }}
      >
        <Container maxWidth="xl">
          <Grid container sx={{ alignItems: "center" }}>
            <Grid lg={9} md={7}>
              <Stack spacing={2}>
                <Typography variant="h2">
                  Оказываем логистические услуги
                </Typography>
                <Typography variant="body1">
                  По всем вопросам звоните по номеру:
                </Typography>
                <Box component="a" href={"tel:+7(914)790-70-72"} style={{textDecoration:"none"}}><Typography variant="h3">+ 7 (914) 790-70-72</Typography></Box>
                <Grid>
                  <MyBotton variant="outlined">Сюрвейеские услуги</MyBotton>
                  <MyBotton variant="outlined">
                    Оформление импортных документов
                  </MyBotton>
                  <MyBotton variant="outlined">
                    Оформление экспортных документов
                  </MyBotton>
                  <MyBotton variant="outlined">экспедирование грузов</MyBotton>
                </Grid>
                <Link
                  to="#section-contacts"
                  title="Обратная связь"
                  style={{ textDecoration: "none", width: "max-content" }}
                  onClick={() => {
                    "ym(90154211,'reachGoal','NextToContactClick') return true;";
                  }}
                >
                  <Button variant="contained" title="" onClick={() => {
                     let anchorTag = document.createElement("a");
                     anchorTag.setAttribute("href", "#section-contacts");
                     anchorTag.click();
                  }}>
                    Обратная связь
                  </Button>
                </Link>
              </Stack>
            </Grid>
            <Grid lg={3} md={5} sx={{ textAlign: "end" }}>
              <Box
                component="img"
                alt="Main"
                src="../images/main.png"
                sx={{
                  display: { md: "flex", xs: "none" },
                  width: { lg: "500px", md: "100%" },
                  margin: "0 auto",
                }}
              ></Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Banner;
