import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { ReactElement, FC } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { SwitchModeButton } from "./SwitchModeButton";
import { HashLink } from "react-router-hash-link";
import HomeIcon from "@mui/icons-material/Home";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link } from "react-router-dom";
const pages = [
  {
    id: 1,
    title: "Главная",
    urlHashLink: "#section-main",
    icon: (
      <HashLink key={1} smooth to={"#section-main"} title="Главная">
        <HomeIcon
          style={{
            marginTop: "5px",
            color: "#229BCF",
          }}
        />
      </HashLink>
    ),
  },
  {
    id: 2,
    title: "Услуги",
    urlHashLink: "#section-services",
    icon: (
      <HashLink
        key={2}
        smooth
        to={"#section-services"}
        style={{ textDecoration: "none", color: "#229BCF" }}
        title="Услуги"
      >
        <HomeRepairServiceIcon
          style={{
            marginTop: "5px",
          }}
        />
      </HashLink>
    ),
  },
  {
    id: 3,
    title: "О компании",
    urlHashLink: "#section-history",
    icon: (
      <HashLink
        key={3}
        smooth
        to={"#section-history"}
        style={{ textDecoration: "none", color: "#229BCF" }}
        title="О компании"
      >
        <BusinessIcon
          style={{
            marginTop: "5px",
          }}
        />
      </HashLink>
    ),
  },
  {
    id: 4,
    title: "Партнеры",
    urlHashLink: "#section-partners",
    icon: (
      <HashLink
        key={4}
        smooth
        to={"#section-partners"}
        style={{ textDecoration: "none", color: "#229BCF" }}
        title="Партнеры"
      >
        <PeopleAltIcon
          style={{
            marginTop: "5px",
          }}
        />{" "}
      </HashLink>
    ),
  },
  {
    id: 5,
    title: "Контакты",
    urlHashLink: "#section-contacts",
    icon: (
      <HashLink
        key={5}
        smooth
        to={"#section-contacts"}
        style={{ textDecoration: "none", color: "#229BCF" }}
        title="Контакты"
      >
        <ContactPageIcon
          style={{
            marginTop: "5px",
          }}
        />
      </HashLink>
    ),
  },
  {
    id: 6,
    title: "Филиалы",
    urlHashLink: "#section-wework",
    icon: (
      <HashLink
        key={6}
        smooth
        to={"#section-wework"}
        style={{ textDecoration: "none", color: "#229BCF" }}
        title="Филиалы"
      >
        <FmdGoodIcon
          style={{
            marginTop: "5px",
          }}
        />
      </HashLink>
    ),
  },
];
//Account for
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Header: FC<any> = (): ReactElement => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <AppBar
        position="static"
        sx={{ height: "6.25rem", justifyContent: "center", flex: "0 0 auto" }}
        id="section-main"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            {/* Мобильная */}
            {/* Это для своей иконки: <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}

            <Typography
              variant="h6"
              noWrap
              component="h6"
              sx={{
                mr: 2,
                width: "20%",
                display: { xs: "none", md: "flex" },
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Link to={"/"} title="Главная">
                <Box
                  component="img"
                  alt="Main"
                  src="../images/Logo.svg"
                  sx={{
                    width: "100%",
                    margin: "0 auto",
                  }}
                ></Box>
              </Link>
            </Typography>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* Декстоп */}
            {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },

                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Box
                component="img"
                alt="Main"
                src="../images/Logo.svg"
                sx={{
                  width: "10rem",
                  margin: "0 auto",
                }}
              ></Box>
            </Typography>
            <Box sx={{ display: { xs: "none", md: "flex" } }} itemScope itemProp="http://schema.org/SiteNavigationElement">
              {pages.map((page) => (
                <HashLink
                  key={page.id}
                  smooth
                  to={page.urlHashLink}
                  style={{ textDecoration: "none" }}
                  itemProp="url"
                >
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 1,
                      color: "white",
                      display: "block",
                      border: "0",
                      "&:hover": {
                        border: 0,
                        background: "#4048980d",
                      },
                    }}
                    variant="outlined"
                  >
                    <Typography variant="h6">{page.title}</Typography>
                  </Button>
                </HashLink>
              ))}
            </Box>

            <Box
              sx={{ ml: 2, width: "20%", textAlign: "right", display: "flex" }}
            >
              <SwitchModeButton />
              {/* <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  f
                </IconButton>
              </Tooltip> */}
              {/* <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<ExpandLessIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        onClick={() => {
          let anchorTag = document.createElement("a");
          anchorTag.setAttribute("href", "#section-main");
          anchorTag.click();
        }}
      >
        {pages.map((item) => (
          <SpeedDialAction
            key={item.id}
            tooltipTitle={item.title}
            icon={item.icon}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </>
  );
};
export default Header;
