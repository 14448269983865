import React, { ReactElement, FC } from "react";

import Footer from "./footer";
import Header from "./header";

const Layout: FC<any> = ({ children }): ReactElement => {
  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <Header />
        <main style={{ flex: "1 0 auto" }}>{children}</main>
        <Footer />
      </div>
    </>
  );
};
export default Layout;
