import { ThemeOptions } from "@mui/material";
export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: { main: "#fff" },
    text: {
      primary: "#000000cc",
    },
  },

  typography: {
    allVariants: {},
    h1: {
      fontWeight: 700,
      lineHeight: 1.1,
      fontSize: "2.75rem",
      "@media (max-width:600px)": {
        fontSize: "1.6rem",
      },
      color: "#1E1945",
      textAlign: "center",
    },
    h2: {
      color: "#1E1945",
      fontWeight: 700,
      lineHeight: 1.1,
      fontSize: "3.5rem",
      "@media (max-width:900px)": {
        fontSize: "2.3rem",
      },
      "@media (max-width:600px)": {
        fontSize: "27px",
      },
    },
    h3: {
      color: "#2C2C2C",
      fontWeight: "800",
      fontSize: "32px",
      "@media (max-width:900px)": {
        fontSize: "25px",
      },
    },
    h6: {
      color: "#1E1945",
      fontWeight: 500,
      lineHeight: "1.75",
      fontSize: "1rem",
      "@media (max-width:1300px)": {
        fontSize: "0.6rem",
      },
    },
    body1: {
      color: "#2C2C2C",
      fontSize: "1.25rem",
      lineHeight: "1.6",
      fontWeight: 400,
      marginTop: 2,
    },
    body2: {},
 
    subtitle1: {},
    button: {},
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          // textSizeAdjust: "none",
          textSizeAdjust: "none",
        },
        body: {
          backgroundColor: "#f6f7f8", //#f6f7f8   #e4ebf3
        },
        root: {
          display: "flex",
          flexDirection: "column",
          Height: "100%",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          padding: "1rem 2rem",
          background: "#229BCF",
          color: "white",
          width: "max-content",
          "&:hover": {
            border: 0,
            background: "#227acf",
          },
          transform: "translateZ(0px) translate3d(0,0,0)",
          perspective: "1000",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        sx: {},
      },
    },
    MuiIcon: {
      defaultProps: {
        sx: { color: "#229BCF" },
      },
    },
    MuiSvgIcon: {
      defaultProps: {

        sx: {fill: "#1E1945"},
      },
    },
  },
};
