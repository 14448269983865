import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import React, { FC, ReactElement } from "react";
import { Link } from "react-router-dom";

const ServicesList: FC<any> = ({ services }): ReactElement => {
  const MyCard = styled(Card)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    alignContent: "center",
    justifyContent: "center",
    height: 280,
    padding: 0,
    border: 0,
    fontSize: "7.5rem",
    transition: "0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.03)",
      padding: 0,
      border: 0,
    },
  });
  return (
    <>
      {services.map((service: any) => {
        return (
          <Grid key={service.id} sm={6} lg={3} xs={12} item>
            <MyCard itemScope itemType="http://schema.org/Product">
              {service.img}
              <Typography component="p" variant="body1" itemProp="name">
                {service.title}
              </Typography>
              {service.id === 4 && (
                <Link
                  to={"./dow/Перечень услуг.pdf"}
                  target="_blank"
                  style={{display: "flex", textDecoration: "none"}}
                >
                  <Button variant="contained">Все услуги</Button>
                </Link>
              )}
            </MyCard>
          </Grid>
        );
      })}
    </>
  );
};
export default ServicesList;
