import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { ReactElement, FC } from "react";

const History: FC<any> = (): ReactElement => {
  return (
    <>
      <Box
        component="section"
        sx={{
          mt: 9,
        }}
        id="section-history"
      >
        <Container maxWidth="xl">
          <Typography component={"h1"} variant="h1">
            О компании
          </Typography>

          <Grid container sx={{ alignItems: "center" }}>
            <Grid xl={4} md={6} xs={12} item>
              <Box
                component="img"
                alt="Main"
                src="../images/Реклама.svg"
                sx={{
                  margin: "0 auto",
                  display: {
                    xs: "flex",
                    sm: "block"
                  },
                  width: {
                    xs: "100%",
                    sm: "400px",
                  },
                }}
              ></Box>
            </Grid>
            <Grid xl={8} md={6} xs={12} item>
              <Stack spacing={2}>
                <Typography component={"h2"} variant="h3">
                  История
                </Typography>
                <Typography component={"p"} variant="body1">
                  Когда-то мы начинали с простого пересчета продукции и проверки
                  его качества. Постепенно мы набирались опыта и со временем
                  могли не только подыскать клиенту нужную партию рыбопродукции,
                  но и взять на себя полную ответственность за транспортировку
                  груза по пути следования (выгрузить с судна, погрузить в
                  контейнеры или вагоны и доставить до склада клиента).
                </Typography>
                {/* <Button variant="contained" title="">
                  Посмотреть
                </Button> */}
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default History;
