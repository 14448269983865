import { Box, Typography } from "@mui/material";
import React, { ReactElement, FC } from "react";

const WeWork: FC<any> = ({ children }): ReactElement => {
  return (
    <>
      <Box
        component="section"
        sx={{
          mt: 9,
        }}
        id="section-wework"
      >
        <Typography component={"h1"} variant="h1">
          Карта где работаем
        </Typography>
        <Box mt={1}>
          {/* <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A552231b8c7585c52b0f84c421d64f9a6663dd3c2863eadaab488f76a6e046dfa&amp;source=constructor"
            width="100%"
            height="600"
            title="maps-tsk"
            style={{ border: "0" }}
          ></iframe> */}
          <iframe
            src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=66013936902"
            width="100%"
            height="600"
            title="maps-tsk"
            style={{ border: "0" }}
          ></iframe>
        </Box>
      </Box>
    </>
  );
};
export default WeWork;
