import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";

import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

interface IFormInputs {
  name: string;
  email: string;
  favoriteNumber: number | null;
  desciption: string;
}

const defaultData = {
  name: "",
  email: "",
  favoriteNumber: null,
  desciption: "",
};

const Form: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: defaultData,
  });

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    console.log("data", data);
 
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    // let response = await fetch(
    //   "http://localhost:8000/api/sendMail",
    //   requestOptions
    // ).then(response => console.log(response))
    axios
      .post('/mail.php', data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

     reset();
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: {md: 1, xs: 0}, maxWidth: "100%" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
 
        <Grid item xs={12} textAlign={"left"}>
          <Typography variant="h3" component={"h3"}>
            Отправьте нам сообщение
          </Typography>
          <Typography variant="body1" component={"p"}>
            Если у вас есть какие-то вопросы или предложения по сотрудничеству -
            заполните форму ниже
          </Typography>
        </Grid>
        <Grid item xs={12}>
        {/* <TextField
            id="name"
            label="Имя"
            name="_token"
            variant="filled"
            type={"hidden"}
            value="{{ csrf_token() }}"
            fullWidth
          /> */}
          <TextField
            id="name"
            label="Имя"
            sx={{
              color: "#2C2C2C",
              "& label.Mui-focused": {
                color: "#2C2C2C", // focus
              },
              ".css-29upoj-MuiInputBase-root-MuiFilledInput-root:after": {
                borderBottom: "2px solid #2C2C2C"
              }
            }}
            variant="filled"
            InputProps={{
              ...register("name", {
                required: true,
                pattern: {
                  value: /\S/,
                  message: "Вы нечего не ввели",
                },
                maxLength: {
                  value: 20,
                  message: "Вы ввели слишком большой Имя",
                },
              }),
              inputProps: {
                maxLength: 20,
              },
            }}
            error={Boolean(errors.name)}
            type={"text"}
            helperText={
              errors.name ? "Вы нечего не ввели" : "Введите только слова"
            }
            fullWidth
          />
          <TextField
            id="email"
            label="email"
            variant="filled"
            sx={{
              color: "#2C2C2C",
              "& label.Mui-focused": {
                color: "#2C2C2C", // focus
              },
              ".css-29upoj-MuiInputBase-root-MuiFilledInput-root:after": {
                borderBottom: "2px solid #2C2C2C"
              }
            }}
            InputProps={{
              ...register("email", {
                required: {
                  value: true,
                  message:
                    "Вам необходимо указать действительный адрес электронной почты",
                },
                pattern: {
                  value: /^\S+@\S+.\S+\S$/i,
                  message: "Вы ввели не корректный email",
                },
                maxLength: {
                  value: 48,
                  message: "Вы ввели слишком большой email",
                },
              }),
              inputProps: {
                maxLength: 48,
              },
            }}
            error={errors.email ? true : false}
            type={"email"}
            helperText={
              errors.email
                ? errors.email.message
                : "В формате: your-name@email.com"
            }
            fullWidth
            autoCorrect="f"
          />

          <TextField
            id="number"
            label="Номер телефона"
            variant="filled"
            sx={{
              color: "#2C2C2C",
              "& label.Mui-focused": {
                color: "#2C2C2C", // focus
              },
              ".css-29upoj-MuiInputBase-root-MuiFilledInput-root:after": {
                borderBottom: "2px solid #2C2C2C"
              }
            }}
            InputProps={{
              ...register("favoriteNumber", {
                required: {
                  value: true,
                  message:
                    "Вам необходимо указать действительный номер телефона!",
                },
                pattern: {
                  value: /^[0-9+-]+$/,
                  message: "Вы ввели некоректные данные",
                },
                minLength: {
                  value: 6,
                  message: "Вы не ввели минимальный набор символов!",
                },
                maxLength: {
                  value: 14,
                  message: "Вы ввели слишком большой набор символов!",
                },
              }),
              inputProps: {
                maxLength: 14,
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.favoriteNumber ? true : false}
            type={"number"}
            helperText={
              errors.favoriteNumber
                ? errors.favoriteNumber.message
                : "В формате: 89007685439"
            }
            fullWidth
          />
          <TextField
            id="filled-multiline-static"
            label="Сообщение"
            variant="filled"
            sx={{
              color: "#2C2C2C",
              "& label.Mui-focused": {
                color: "#2C2C2C", // focus
              },
              ".css-1futczp-MuiInputBase-root-MuiFilledInput-root:after": {
                borderBottom: "2px solid #2C2C2C"
              }
            }}
            InputProps={{
              ...register("desciption", {
                required: {
                  value: true,
                  message: "Введите какой-нибудь текст",
                },
                minLength: {
                  value: 6,
                  message: "This number is too short, not gotta fly, try again",
                },
              }),
            }}
            type={"text"}
            helperText={
              errors.desciption
                ? errors.desciption.message
                : "Пример: Название компании, услуги, коммерческое предложение и прочее"
            }
            fullWidth
            error={errors.desciption ? true : false}
            rows={4}
            multiline
          />
          <Button variant="contained" type="submit">
            Отправить
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default Form;
