import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { ReactElement, FC } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import Form from "../Form";
const Contacts: FC<any> = (): ReactElement => {
  return (
    <>
      <Box
        component="section"
        sx={{
          mt: 9,
        }}
        id="section-contacts"
      >
        <Container maxWidth="xl">
          <Typography component={"h1"} variant="h1">
            Контакты
          </Typography>
          <Grid container mt={1} spacing={5}>
            <Grid item md={6} xs={12}>
              <Stack spacing={2}>
                <Typography variant="h3" component="h2">
                  Основная связь с компанией
                </Typography>
                <Stack
                  direction={{ sm: "row", xs: "column" }}
                  spacing={{ md: 3, xs: 3 }}
                >
                  <Card
                    sx={{ width: { md: "50%", xs: "100%" }, height: "100%" }}
                    itemScope
                    itemType="https://schema.org/Person"
                  >
                    <CardContent>
                      <Typography variant="body1" itemProp="jobTitle">
                        Директор
                      </Typography>
                      <Typography variant="body1" itemProp="name">
                        Тимченко Ольга Николаевна
                      </Typography>

                      <Typography variant="body1" itemProp="telephone">
                        +7 (914) 790-70-72
                      </Typography>
                      <Typography variant="body1" itemProp="email">
                        olgatsk@yandex.ru
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{ width: { md: "50%", xs: "100%" }, height: "100%" }}
                    itemScope
                    itemType="https://schema.org/Person"
                  >
                    <CardContent>
                      <Typography variant="body1" itemProp="jobTitle">
                        Менеджер
                      </Typography>
                      <Typography variant="body1" itemProp="name">
                        Засорина Анастасия Андреевна
                      </Typography>

                      <Typography variant="body1" itemProp="telephone">
                        +7 (914) 701-96-95
                      </Typography>
                      <Typography variant="body1" itemProp="email">
                        office@tsk-dv.ru
                      </Typography>
                    </CardContent>
                  </Card>
                </Stack>
                <Card style={{}}>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      Для добавления контакта в телефон просто наведите камеру
                    </Typography>
                    <Box
                      component="img"
                      alt="Main"
                      height={300}
                      width={300}
                      src="../images/Qrcode300X300.webp"
                    ></Box>
                    <Typography>Присоединяйтесь</Typography>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Box
                        component="a"
                        href="https://wa.me/89147907072"
                        title="WhatsApp"
                      >
                        <Tooltip
                          title="WhatsApp"
                          enterTouchDelay={1}
                          leaveTouchDelay={3000}
                        >
                          <WhatsAppIcon
                            sx={{
                              width: 50,
                              height: 50,
                              color: "#82e97a",
                              transition: "0.3s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.05)",
                                border: 0,
                              },
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <Box
                        component="a"
                        href="https://t.me/olgaTimchenkoTsk"
                        title="Telegram"
                      >
                        <TelegramIcon
                          sx={{
                            width: 50,
                            height: 50,
                            color: "#4d9ad1",
                            transition: "0.3s ease-in-out",
                            "&:hover": {
                              transform: "scale(1.05)",
                              border: 0,
                            },
                          }}
                        />
                      </Box>

                      <Box
                        component="a"
                        href="mailto:office.tsk@mail.ru&cc=olgatsk@yandex.ru"
                        title="Email"
                      >
                        <EmailIcon
                          sx={{
                            width: 50,
                            height: 50,
                            color: "#221B52",
                            transition: "0.3s ease-in-out",
                            "&:hover": {
                              transform: "scale(1.05)",
                              border: 0,
                            },
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography>
                      Просто нажмите на нужный вам месседжер
                    </Typography>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
            <Grid item md={6} xs={12}>
              <Form />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Contacts;
