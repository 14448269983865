import React from "react";
import Banner from "../components/sections/banner";
import Contacts from "../components/sections/contacts";
import Partners from "../components/sections/partners";
import Services from "../components/sections/services";
import History from "../components/sections/history";
import WeWork from "../components/sections/WeWork";
export default function Home() {
  return (
    <>
      <Banner />
      <Services />
      <Partners />
      <Contacts />
      <History />
      <WeWork />
    </>
  );
}
