import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { ReactElement, FC } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const Partners: FC<any> = (): ReactElement => {
  return (
    <>
      <Box
        component="section"
        sx={{
          mt: 9,
        }}
        id="section-partners"
      >
        <Container maxWidth="xl">
          <Typography component={"h1"} variant="h1">
            Партнеры
          </Typography>

          <Stack
            direction="column"
            spacing={4}
            justifyContent="center"
            mt={1}
            style={{ overflowX: "auto", padding: "10px" }}
          >
            <Stack
              direction="row"
              spacing={4}
              mt={1}
              justifyContent="space-between"
              alignContent={"center"}
              alignItems="center"
            >
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/Вичюнай.png"
                  height="auto"
                  width="150px"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
              </Box>
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/РРПК.svg"
                  height="auto"
                  width="150px"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
              </Box>
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/РКИЛ.png"
                  height="auto"
                  width="150px"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
              </Box>
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/СигмаМаринТехнолоджи.svg"
                  height="auto"
                  width="150px"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
              </Box>
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  height="auto"
                  width="100px"
                  minWidth="100px"
                  src="../images/partners/СириусВл.png"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                    "&path": {
                      fill: "#8a2be200",
                    },
                  }}
                ></Box>
              </Box>
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/УльтраФиш.svg"
                  height="auto"
                  width="150px"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={4}
              mt={1}
              justifyContent="space-between"
            >
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/Остров.svg"
                  height="auto"
                  width="100%"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
              </Box>
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  width="100%"
                  height="auto"
                  minWidth="100px"
                  src="../images/partners/Дальрефтранс.jpg"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
              </Box>
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/Антей Север.png"
                  height="auto"
                  width="150px"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                      color: "#1E1945"
                    },
                  }}
                ></Box>
              </Box>
              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/609A906ECAF76.png"
                  height="auto"
                  width="150px"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
              </Box>

              <Box
                height="200px"
                width="200px"
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/Норебо.svg"
                  height="100%"
                  width="100%"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
              </Box>
              <Box
                height="200px"
                width="200px"
                textAlign={"center"}
                display="flex"
                alignSelf="center"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                sx={{
                  transition: "0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.03)",
                    padding: 0,
                    border: 0,
                  },
                }}
              >
              <Box
                  component="img"
                  alt="Main"
                  src="../images/partners/Unifrost.svg"
                  height="100%"
                  width="100%"
                  minWidth="100px"
                  sx={{
                    transition: "0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      padding: 0,
                      border: 0,
                    },
                  }}
                ></Box>
               
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
export default Partners;
