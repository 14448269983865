import { Box, Container, Grid, Typography } from "@mui/material";
import React, { ReactElement, FC, useState } from "react";
import ServicesList from "../ServicesList";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DescriptionIcon from "@mui/icons-material/Description";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
type Service = {
  id: number;
  title: string;
  img: ReactElement;
  link: string;
};
const Services: FC<any> = (): ReactElement => {
  const [ServicesCollection, setServicesState] = useState<Service[]>([
    {
      id: 1,
      title: "Сюрвейерские услуги",
      img: <DirectionsBoatIcon fontSize="inherit" />,
      link: "",
    },
    {
      id: 2,
      title: "Оформление импортных и экспортных документов",
      img: <DescriptionIcon fontSize="inherit" />,
      link: "",
    },
    {
      id: 3,
      title: "Экспедирование грузов",
      img: <LocalShippingIcon fontSize="inherit" />,
      link: "",
    },
    {
      id: 4,
      title: "Список всех услуг",
      img: <PictureAsPdfIcon fontSize="inherit" sx={{ color: "red" }} />,
      link: "../accets/Dowloads/Перечень услуг.pdf",
    },
  ]);
  return (
    <>
      <Box component="section" sx={{ mt: 9}} id="section-services">
        <Container maxWidth="xl" >
          <Typography component={"h1"} variant="h1">
            Услуги
          </Typography>
          <Grid
            container
            mt={1}
            spacing={3}
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <ServicesList services={ServicesCollection} />
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Services;
