import { Box, Container, Grid, Typography } from "@mui/material";
import React, { ReactElement, FC } from "react";

const Footer: FC<any> = (): ReactElement => {
  return (
    <>
      <Box
        component="footer"
        sx={{
          flex: "0 0 auto",
           mt: -1,
          backgroundColor: "primary.main",
        
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            textAlign={"center"}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height= "6.25rem"
          >
            <Grid item xs></Grid>
            <Grid item xs={6}>
              <Typography component="p" variant="h6">© 2010-2023 Все права защищены. ООО “ТСК”</Typography>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Footer;
